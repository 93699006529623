<template>
    <div class="schedling">
         <div style="display: flex;">
            <Headbuttom></Headbuttom>
            <Tabheader @handRegister="handRegister" @handFromshow="handFromshow" :titlename="titlename"></Tabheader>
        </div>
        
         <div class="attheadfrom" v-show="flag">
            <el-form :inline="true" :model="searchParams" class="demo-form-inline">
                <!-- <el-form-item label="企业名称">
                    <el-select v-model="formInline.region" placeholder="活动区域">
                    <el-option label="手工签到" value="shanghai"></el-option>
                    <el-option label="区域二" value="beijing"></el-option>
                    </el-select>
                </el-form-item> -->
                <el-form-item :label="$t('devtable.bm')">
                    <Select @handleCheckChange="handleCheckChange"></Select>
                </el-form-item>
                <el-form-item :label="$t('devtable.xm')">
                    <el-input v-model="searchParams.name" :placeholder="$t('devtable.xm')"></el-input>
                </el-form-item>
                <el-form-item :label="$t('devtable.ghHH')">
                    <el-input v-model="searchParams.number" :placeholder="$t('devtable.ghHH')"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="onSubmit">{{$t('devtable.cx')}}</el-button>
                </el-form-item>
            </el-form>
        </div>
        <Deletedialog @userhandleClose="userhandleClose" :userdialog="delDialog" @userdialogVisibletrue="userdialogVisibletrue">
            <el-form :model="delform">
                <el-form-item :label="$t('devtable.yhm')" :label-width="formLabelWidth">
                <el-input v-model="delform.user_name_input" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item :label="$t('devtable.mm')" :label-width="formLabelWidth">
                <el-input type="password" v-model="delform.user_pass_input" autocomplete="off"></el-input>
                </el-form-item>
            </el-form>
        </Deletedialog>
        <Tables @handlpages="handlpages" @handleSizeChange="handleSizeChange" @handlerow="handlerow" :tableLabel="tableLabel" :tableData="tableData" :flags="flags" :configs="configs" :operation="operation"></Tables>
        <Manualdiolg @addManualSuccess="addManualSuccess"  ref="manualdiolg"></Manualdiolg >
        <ManualEdit @editManualSuccess="editManualSuccess" ref="manualedit"></ManualEdit>
    </div>
</template>
<script>
import Tables from '@/components/table'
import Tabheader from './components/tabheader'
import Manualdiolg from './components/manualdiolg'
import ManualEdit from './components/manualedit'
import Select from '@/components/selectree/selectchoice'
import {attendanceServer} from '../../api/uesr'
import {formatDate} from '../../utils/date'
import Deletedialog from '@/components/dialog/userfrom'
export default {
    components:{
        Tables,
        Tabheader,
        Manualdiolg,
        Select,
        Deletedialog,
        ManualEdit
    },
    data(){
        return{
            flag:false,
            formLabelWidth:'85px',
            searchParams: {
                name: '',
                number: '',
                dept_id: ''
            },
            delDialog:{
              dialogVisible:false,
              title:this.$t('devtable.tsxx')
            }, 
            delform: {
              user_name_input: '',
              user_pass_input: ''
            },
            delItemData: '',             
           
            
            tableData: [
            ],
            flags: {
                show: false, // 多选是否显示
                ishow: false // 序号是否显示
            },
            // 分页数据
            configs: {
                page_index:1,
                pagesize: 10,
                total: 200,
                loading: false // 加载状态
            },
            
            UserInfo:''
        }
    },
    computed:{
         titlename:function(){
             return{
                name:this.$t('tablename.zj'),
                placeholdername:this.$t('devtable.qdjcx')
            }},
        operation:function(){
            return{
                width:'200px',
                data:[
                {
                    name: this.$t('devtable.xg'),
                    type: 'danger'
                  },
                  {
                    name: this.$t('tablename.sc'),
                    type: 'danger'
                  },
                ]
            }
        } ,
        tableLabel:function(){
            return [
                {
                    prop: 'user_id',
                    label: this.$t('devtable.ygid'),
                    align: 'center'                    
                }
                ,{
                    prop: 'user_name',
                    label: this.$t('devtable.ygxm'),
                    align: 'center'
                },
                {
                    prop: 'user_number',
                    label: this.$t('devtable.ghHH'),
                    align: 'center'
                },
                {
                    prop: 'dept_name',
                    label: this.$t('devtable.bm'),
                    align: 'center'
                },
                {
                    prop: 'check_in_time_hint',
                    label: this.$t('devtable.qdsj'),
                    align: 'center'
                },
                {
                    prop: 'remark',
                    label: this.$t('devtable.bz'),
                    align: 'center'                    
                }
            ]
        }
    },
    mounted () {
        this.UserInfo = JSON.parse(window.localStorage.getItem('user'));
      this.getManualList(1) 
    },
    methods:{
        handlerow (v, name) {
            if(name == '修改' || name == "Modify"){ 
                console.log(v)
                this.$refs.manualedit.setEditData(v.id)
                this.$refs.manualedit.centerDialogVisible=true
            }else if(name == '删除' || name=="Delete"){
                this.delItemData = v
                this.delDialog.dialogVisible=true;
            }
        },
        handlpage(page){
            this.getManualList(page);
        },
        //关闭删除框
        userhandleClose(){
            console.log("userhandleClose");
            this.delDialog.dialogVisible=false
        },
        //确认删除
        userdialogVisibletrue(){
             
            if(this.delform.user_name_input != this.UserInfo.user_name_save){
                this.$message({
                    type: 'warning',
                    message: this.$t('devtable.yhmsryw')
                })
                return false;
            }

            if(this.delform.user_pass_input != this.UserInfo.user_pass_save){
                this.$message({
                    type: 'warning',
                    message: this.$t('devtable.yhmmsryw')
                })
                return false;
            }

            const params = {
                method: 'att.checkin.delete',
                id: this.delItemData.id,
                agent_id: this.UserInfo.agent_id
            }

            attendanceServer(params).then(res => {
                console.log(res);
                if(res.data.code == 0){
                    this.$message({
                        type: 'success',
                        message:  this.$t('devtable.sccg')
                    })
                    this.delDialog.dialogVisible=false
                    this.getManualList(1);
                }else{
                    this.$message({
                        type: 'warning',
                        message:  this.$t('devtable.scsb')
                    })                    
                }
            })
        },
        //添加手工签到成功
        addManualSuccess(){
            this.getManualList(1)
        },
        editManualSuccess(){
            this.getManualList(1)
        },        
        handleCheckChange(val){
            console.log('sel196',val)
            this.searchParams.dept_id = val;           
        },   
        handRegister(){
            this.$refs.manualdiolg.centerDialogVisible=true
        },  
        handFromshow(){
            this.flag = !this.flag
        },
        onSubmit(){
            this.getManualList(1);
        },
        handlpages(v){
            this.configs.page_index=v
            this.getManualList()
        },
        handleSizeChange(v){
            this.configs.page_index=1
            this.configs.pagesize=v
            this.getManualList()
        },
        getManualList(pageIndex){
            const params = {
                method: 'att.checkins.get',
                agent_id: this.UserInfo.agent_id,
                page_index: pageIndex?pageIndex:this.configs.page_index, 
                page_size: this.configs.pagesize,                
                name: this.searchParams.name,
                number: this.searchParams.number,
                dept_id: this.searchParams.dept_id
            }

            attendanceServer(params).then(res => {
                console.log(res);
                this.tableData = res.data.data.records;
                this.tableData.forEach(el=>{
                    if(el.check_in_time.length == 0 || el.check_in_time == 0){
                        el.check_in_time_hint =''
                    }else{
                        el.check_in_time_hint ={
                            name: ''+formatDate(new Date(el.check_in_time * 1000), 'yyyy-MM-dd hh:mm'),
                            type: 'primary',
                            state: true                            
                        }
                    }
                })
                if(params.page_index==1){
                    this.configs.total = res.data.data.recordCount;
                }
            })
        } 
    }
}
</script>
<style lang="less" scoped>
.schedling{
    padding: 20px;
    background-color: #fff;
    .attheadfrom{
        padding: 20px;
        margin-top: 10px;
    }
}
</style>