<template>
    <div>
        <el-dialog
            :title="$t('devtable.xgqd')"
            :visible.sync="centerDialogVisible"
            width="45%"
            append-to-body
            center>
            <!-- <div class="schdolgfoot">
                <div class="yuang"> 员工</div>
                <div class="staff">
                    <div class="staffleft">
                        <div class="stafflefttop">
                            <span style="flex:2;line-height: 32px;">部门： </span>
                            <SelectSingle @handleCheckChange="handleCheckChange"></SelectSingle>
                        </div>
                        <el-row  style="display: flex;padding-left:55px">
                            <el-input size="small" v-model="input_name" placeholder="示列:姓名" style="flex:3;margin-right:20px"></el-input>
                            <el-row  style="flex:2;line-height: 32px;"> <el-button type="primary" size="small" @click="searchWithName">查询</el-button></el-row>
                        </el-row>
                    </div>
                    <div class="staffright">
                        <p>已选员工</p>
                    </div>
                </div>
                <div style="padding-left:25px;overflow: hidden;">
                    <Tableva ref="transTab"></Tableva>
                </div>
            </div>             -->
            <div class="details">
                <el-col :span="4" style="line-height: 32px;">
                    {{$t('devtable.qdrq')}}
                </el-col>
                <el-col :span="18">
                    <el-date-picker
                    v-model="check_date"
                    size="small"
                    type="datetime"
                    value-format="timestamp"
                    format="yyyy-MM-dd HH:mm"
                    :placeholder="$t('devtable.xzrq')">
                    </el-date-picker>
                </el-col>
            </div>
            
            <div class="details">
                <el-col :span="3" style="line-height: 32px;">
                    {{$t('devtable.bz')}}
                </el-col>
                <el-col :span="18">
                    <el-input
                    placeholder=""
                    v-model="input_remark"
                    clearable>
                    </el-input>
                </el-col>
            </div>
            
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="manualEditConfirm">{{$t('tablename.qd')}}</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
//import SelectSingle from '@/components/selectree/selectchoice'
//import Tableva from './tablelave'
import {attendanceServer} from '../../../api/uesr'
import {formatDate} from '../../../utils/date'
export default {
    // components:{
    //     SelectSingle,
    //     Tableva
    // },    
    data(){
        return{
            //dept_id: '',           
            //input_name: '',
            centerDialogVisible: false,
            check_date: '',
            input_remark: '',
            id: '',
            user_id: '',
            UserInfo:''
        }
    },
    mounted(){
        this.UserInfo = JSON.parse(window.localStorage.getItem('user'));
    },
    methods:{
        //修改签到
        manualEditConfirm(){
            const params = {
                method: 'att.checkin.edit',
                id: this.id,
                user_id: this.user_id,
                check_in_time: this.check_date/1000,
                remark: this.input_remark,
                agent_id: this.UserInfo.agent_id                
            }
            attendanceServer(params).then(res => {
                console.log(res);
                if(res.data.code == 0){
                    this.$message({
                        type: 'success',
                        message: this.$t('devtable.qdxgcg'),
                    })
                    this.centerDialogVisible = false;
                    this.$emit('editManualSuccess')
                }else{
                    this.$message({
                        type: 'warning',
                        message: this.$t('devtable.qdxgsb'),
                    })                    
                }                
            })  
        },
        //获取并设置签到信息
        setEditData(id){
            const params = {
                method: 'att.checkin.get',
                id: id,
                agent_id: this.UserInfo.agent_id                
            }
            attendanceServer(params).then(res => {
                console.log(res);
                if(res.data.code == 0){
                    this.id = res.data.data.id;
                    this.user_id = res.data.data.user_id;
                    this.input_remark = res.data.data.remark;
                    this.check_date = res.data.data.check_in_time * 1000;// ''+formatDate(new Date(res.data.data.check_in_time * 1000), 'yyyy-MM-dd');
                    console.log(this.check_date);
                }
            })            
        }
    }
}
</script>
<style lang="less" scoped>
.schdolgfoot{
    border: 1px solid #ccc;
    position: relative;
    height: 435px;
    margin-top: 20px;
    .yuang{
        width: 50px;
        height: 20px;
        text-align: center;
        background-color: #fff;
        position: absolute;
        top:-10px;
        left: 20px;
    }
    .staff{
        display: flex;
        padding: 20px;
        .staffleft{
            flex: 1;
            .stafflefttop{
                display: flex;
                margin-bottom: 20px;
                margin-left: 10px;
            }
        }
        .staffright{
            flex: 1;
            p{
                text-align: center;
            }
        }
    }
}
.details{
    padding: 25px;
}
.line{
    text-align: center;
    line-height: 30px;
}
</style>